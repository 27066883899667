// Меню
.menu {
  &[data-sidebar="open"] {
    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__content {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &__content {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");
    transform : translateX(-100%);
  }
}

// Первоначальный блок
.primal {
  &__item {
    animation: primal-item 6s ease-in-out forwards infinite;

    &--2 {
      animation-delay: 0.5s;
    }

    &--3 {
      animation-delay: 0.9s;
    }
  }
}

@keyframes primal-item {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
}

// Проект
.project {
  &__thumb.swiper-slide-thumb-active {
    @apply opacity-50 pointer-events-none;
  }
}

// Готовые решения
.solutions {
  &__gradient {
    &::after {
      background: linear-gradient(90deg, theme("colors.black.DEFAULT") 9.78%, transparent 100%);

      @apply content-auto absolute inset-0;
    }
  }
}

// Квиз
.quiz {
  &-slider {
    &[data-quiz="stop"] .swiper-button-next {
      @apply opacity-50 pointer-events-none;
    }

    &[data-quiz-end] .swiper-button-next {
      @apply hidden;
    }

    &:not([data-quiz-end]) .quiz-submit {
      @apply hidden;
    }
  }
}

// Достижения
.achievements {
  &__item {
    background-image: linear-gradient(216.09deg, theme("colors.white.DEFAULT / 50%") 0%, transparent 48.5%, theme("colors.white.DEFAULT / 50%") 100%);
  }

  &__path {
    animation-delay: var(--path-delay);
  }
}

// О нас
.about {
  &__item {
    background-image: linear-gradient(160.48deg, theme("colors.white.DEFAULT / 30%") 0%, transparent 61.46%);
  }

  &__grid {
    &::after {
      background-image: linear-gradient(180deg, transparent 0%, #4C75AA 100%);

      @apply content-auto absolute left-0 right-0 bottom-0 pointer-events-none w-full h-40;

      @screen md {
        @apply h-32;
      }
    }
  }
}

// Сотрудничество
@mixin cooperation() {
  .cooperation {
    &__card {
      background-image: linear-gradient(216.09deg, theme("colors.white.DEFAULT / 50%") 0%, transparent 48.5%, theme("colors.white.DEFAULT / 50%") 100%);

      @apply bg-second scale-110;
    }

    &__bg {
      @apply bg-white bg-opacity-30;
    }

    &__next {
      @apply opacity-50 visible;
    }

    &__number {
      @apply text-primary opacity-100;
    }

    &__text {
      @apply opacity-80 visible;
    }

    &__circle {
      @apply bg-primary;
    }

    &__bottom {
      @apply visible opacity-100 scale-110;
    }
  }
}

.cooperation {
  &__info {
    background-image: linear-gradient(200.9deg, transparent 13.82%, #334B8D 86.18%);
  }

  &-slider {
    &::after {
      @apply content-auto absolute left-0 right-0 bottom-32 border-b border-black border-dashed border-opacity-20;

      @screen md {
        @apply bottom-40;
      }
    }
  }

  &-slide {
    @media (hover) {
      &:hover {
        @include cooperation();
      }
    }

    @media(pointer: coarse) {
      &.swiper-slide-active {
        @include cooperation();
      }
    }
  }
}

// Вопрос-ответ
.questions {
  &__accordion[data-accordion="active"] &__line {
    @apply rotate-0;
  }
}

// Контакты
.contacts {
  &__item {
    background-image: linear-gradient(216.09deg, theme("colors.white.DEFAULT / 50%") 0%, transparent 48.5%, theme("colors.white.DEFAULT / 50%") 100%);
  }
}

// 404
.error {
  &__title {
    background             : linear-gradient(200.9deg, transparent 13.82%, #263D7D 63.22%), theme("colors.primary.DEFAULT");
    -webkit-text-fill-color: transparent;

    @apply bg-clip-text;

    @screen sm {
      font-size: 268px;
    }
  }
}