@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import "@fancyapps/ui/dist/fancybox/fancybox.css";
  @import "swiper/css/bundle";
  @import "./ui-kit/fonts";
  @import "./ui-kit/basic";
  @import "./ui-kit/scrollbar";
  @import "./ui-kit/custom";
  @import "./ui-kit/waved";
}

@layer components {
  @import "./ui-kit/load";
}

@import "./front-end/main";