.loader {
  @apply flex items-center justify-center absolute inset-0 pointer-events-none;

  &-progress {
    animation: progress 1.8s linear infinite;

    @apply text-40 text-primary;
  }

  .icon {
    animation: loader 1.5s ease-in-out infinite;
  }
}

.preloader {

  &::before,
  &::after {
    animation: preloader-pulse 1s ease-out infinite;

    @apply content-auto absolute inset-0 border-4 border-solid border-current rounded-max w-full h-full;
  }

  &::after {
    animation-delay: -0.5s;
  }

  @apply flex items-center justify-center relative text-primary w-20 h-20;
}

@keyframes progress {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    stroke: {
      dasharray : 1px, 200px;
      dashoffset: 0;
    }
  }

  50% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -15px;
    }
  }

  100% {
    stroke: {
      dasharray : 100px, 200px;
      dashoffset: -125px;
    }
  }
}

@keyframes preloader-pulse {

  0% {
    opacity  : 1;
    transform: scale(0);
  }

  100% {
    opacity  : 0;
    transform: scale(1);
  }
}